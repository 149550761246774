import { Button, Container, Row } from "react-bootstrap";
import Select from "react-select";
import { useGetServicesQuery } from "../../../redux/services/servicesApi";
import { convertToSelectLabel } from "../../../Helpers/convertToSelectLabel";
import { customStyles } from "../../../styles/reactSelectCustomStyles";
import { useState } from "react";
import { useGetDetailedFacilitiesQuery } from "../../../redux/services/detailedFacilitiesApi";
import ShortestQueueFilters from "./WidgetFilters/ShortestQueueFilters";
import ShortestQueueResults from "./Results/ShortestQueueResults";
import { Loading } from "../../atoms/Loading/Loading";
import { shortestQueueSelectAllOptions } from "../../../Helpers/shortestQueueSelectAllOptions";
import styles from "./WidgetShortestQueue.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatNameForUrl } from "Helpers/normalizeString";

const WidgetShortestQueue = () => {
  const { data: servicesData } = useGetServicesQuery({
    active: "1",
    displayed: "1",
  });
  const { t } = useTranslation();
  const defaultQueue = {
    value: "1",
    label: t("widget_shortest_queue_option_normal_label"),
  };
  const userData = useSelector((state: any) => state.user.authUser);
  const provinces = useSelector((state: any) => state.province.provinces);
  const defaultProvince = provinces.find(
    (province: any) => formatNameForUrl(province.name) === userData.settings.MailingState
  );
  const [selectedFilters, setSelectedFilters] =
    useState<ShortestQueueSelectedFilters>({
      cities: shortestQueueSelectAllOptions(),
      provinces: defaultProvince
        ? { id: defaultProvince.id.toString(), label: defaultProvince.name }
        : shortestQueueSelectAllOptions("province"),
    });

  const [filters, setFilters] = useState<ShortestQueueSelectedFilters>({
    cities: shortestQueueSelectAllOptions(),
    provinces: defaultProvince
      ? { id: defaultProvince.id.toString(), label: defaultProvince.name }
      : shortestQueueSelectAllOptions("province"),
  });

  const [selectedRequiredOptions, setSelectedRequiredOptions] =
    useState<ShortestQueueSelectedRequiredOptions>({
      service: convertToSelectLabel(servicesData?.data, "name")[0],
      queue: defaultQueue,
    });
  const { data: detailedFacilitiesData } = useGetDetailedFacilitiesQuery({
    serviceId:
      selectedRequiredOptions?.service?.id?.toString() ??
      servicesData?.data?.[0]?.id?.toString(),
    provinceId:
      (filters?.provinces?.id !== 0 && filters?.provinces?.id?.toString()) ??
      "",
    cityId:
      (filters?.cities?.id !== 0 && filters?.cities?.id?.toString()) ?? "",
    queueId: selectedRequiredOptions?.queue?.value ?? "",
    limit: "3",
  });

  const handleFilters = () => {
    setFilters({
      provinces: selectedFilters?.provinces ?? null,
      cities: selectedFilters?.cities ?? null,
    });
  };

  const handleResetFilters = () => {
    setSelectedFilters({
      cities: shortestQueueSelectAllOptions(),
      provinces: defaultProvince
        ? { id: defaultProvince.id, label: defaultProvince.name }
        : shortestQueueSelectAllOptions("province"),
    });
    setFilters({
      provinces: defaultProvince
        ? { id: defaultProvince.id, label: defaultProvince.name }
        : shortestQueueSelectAllOptions("province"),
      cities: shortestQueueSelectAllOptions(),
    });
  };

  const queueSelectOptions = [
    { label: t("widget_shortest_queue_option_normal_label"), value: "1" },
    { label: t("widget_shortest_queue_option_urgent_label"), value: "2" },
  ];

  const servicesDataSelectOptions: ILabel[] =
    servicesData?.data && convertToSelectLabel(servicesData?.data, "name");

  return (
    <Container fluid className={`p-4 containerWithShadow`}>
      <h2 className={`fw-600 fs-22 text-dark-blue mb-5 ${styles.title}`}>
        {t("widget_shortest_queue_title")}
      </h2>
      <p className="fw-400 fs-16 text-dark-blue">
        {t("widget_shortest_queue_p")}
      </p>
      <Row>
        <div className={"col-12 col-xl-6"}>
          <label className={"col-12"}>
            <div className="fw-400 fs-16 text-dark-blue my-2">
              {t("widget_shortest_queue_label1")}
            </div>
            {servicesData ? (
              <Select<ILabel>
                placeholder={t("widget_shortest_queue_select_placeholder")}
                defaultValue={{
                  label: servicesData?.data?.[0]?.name,
                  value: servicesData?.data?.[0]?.name,
                }}
                onChange={(value) =>
                  setSelectedRequiredOptions(
                    (prev: ShortestQueueSelectedRequiredOptions) => ({
                      ...prev,
                      service: value,
                    })
                  )
                }
                options={servicesDataSelectOptions}
                styles={customStyles(false, false)}
                noOptionsMessage={() => (
                  <span>
                    {t("widget_shortest_queue_select_no_results_span")}
                  </span>
                )}
              />
            ) : (
              <Loading />
            )}
          </label>
        </div>
        <div className={"col-12 col-xl-6"}>
          <label className={"col-12"}>
            <div className="fw-400 fs-16 text-dark-blue my-2">
              {t("widget_shortest_queue_label2")}
            </div>

            <Select<ILabel>
              value={selectedRequiredOptions?.queue}
              styles={customStyles(false, false)}
              onChange={(value) =>
                setSelectedRequiredOptions(
                  (prev: ShortestQueueSelectedRequiredOptions) => ({
                    ...prev,
                    queue: value,
                  })
                )
              }
              options={queueSelectOptions}
              noOptionsMessage={() => (
                <span>{t("widget_shortest_queue_select_no_results_span")}</span>
              )}
            />
          </label>
        </div>
      </Row>
      <ShortestQueueFilters
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setFilters={setFilters}
        handleFilters={handleFilters}
        handleResetFilters={handleResetFilters}
      />
      <ShortestQueueResults
        detailedFacilitiesData={detailedFacilitiesData?.data}
        handleResetFilters={handleResetFilters}
      />
      <div className={"p-0 mx-0 mt-4 d-flex justify-content-end"}>
        <Button
          variant="primary"
          className={`${styles.button} text-white`}
          href={process.env.REACT_APP_EXTERNAL_LINK_ONKOSKANER}
          target="_blank"
        >
          {t("widget_shortest_queue_more_button")}
        </Button>
      </div>
    </Container>
  );
};

export default WidgetShortestQueue;
