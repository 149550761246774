import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ProvinceData } from "redux/services/provincesApi";

interface ProvinceState {
  provinces: ProvinceData[];
  loading: boolean;
  error: string | null;
}

const initialState: ProvinceState = {
  provinces: [],
  loading: false,
  error: null,
};
const provinceSlice = createSlice({
  name: "province",
  initialState,
  reducers: {
    fetchProvincesStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchProvincesSuccess: (state, action: PayloadAction<ProvinceData[]>) => {
      state.loading = false;
      state.provinces = action.payload;
    },
    fetchProvincesFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export const { fetchProvincesStart, fetchProvincesSuccess, fetchProvincesFailure } = provinceSlice.actions;
export default provinceSlice.reducer;
