import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Form } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import { InputComponentProps } from "components/molecules/RenderPollQuestion/RenderPollQuestion";
import CustomCalendar from "components/atoms/CustomCalendar/CustomCalendar";
import styles from "./BirthDateInput.module.scss";
import { LiaCalendarAltSolid } from "react-icons/lia";

export default function BirthDateInput({
  question,
  nextPageClicked,
  defaultValue,
  readOnly,
}: InputComponentProps) {
  const { register, setValue, watch, formState: { errors, isSubmitted }, trigger } = useFormContext();
  const watchedValue = watch(question?.fieldName, "");
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef<HTMLDivElement | null>(null);

  const fieldSizeMeta = question?.meta?.find((m) => m.key === "field_size");
  const fieldSize = fieldSizeMeta ? JSON.parse(fieldSizeMeta.value).lg : "12";

  const today = new Date();
  const maxDate = `${today.getFullYear()}-${(today.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(question?.fieldName, defaultValue);
    }
  }, [defaultValue, setValue, question?.fieldName]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDateInputClick = () => {
    if (!readOnly) {
      setShowCalendar(true);
    }
  };

  const validationMeta = question?.meta?.find((m) => m.key === "validation");

  const handleDateChange = (date: Date) => {
    const formattedDate = date.toLocaleDateString('en-CA');
    setValue(question?.fieldName, formattedDate);
    setShowCalendar(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(question?.fieldName, event.target.value);
  };

  const validateDate = (value: string) => {
    if (!value) {
      const requiredMessage = validationMeta ? JSON.parse(validationMeta.value).requiredMessage : "To pole jest wymagane";
      return requiredMessage;
    }

    const dateRegex = /^\d{4}-\d{1,2}-\d{1,2}$/;
    
    if (!dateRegex.test(value)) {
      return "Nieprawidłowy format daty. Użyj YYYY-MM-DD";
    }

    const [year, month, day] = value.split("-").map(Number);
    
    const isValidDate = new Date(year, month - 1, day).getDate() === day;

    if (!isValidDate || month < 1 || month > 12 || day < 1 || day > 31) {
      return "Nieprawidłowa data";
    }

    const inputDate = new Date(year, month - 1, day);
    if (inputDate > new Date(maxDate)) {
      return `Data nie może być późniejsza niż ${maxDate}`;
    }

    return true;
  };

  const handleBlur = async () => {
    const result = await trigger(question?.fieldName);
    
    if (result && watchedValue) {
      const [year, month, day] = watchedValue.split("-").map(Number);

      const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      setValue(question?.fieldName, formattedDate);
    }
  };

  return (
    <div className={`mb-2 mt-2 col-lg-${fieldSize}`}>
      <Form.Label className="fw-600 fs-16 mb-0">
        {question?.question}
        {validationMeta && JSON.parse(validationMeta.value).isRequired && (
          <span className="text-validation">*</span>
        )}
      </Form.Label>
      <div className={styles.inputContainer}>
        <Form.Control
          {...register(question?.fieldName, {
            validate: validateDate,
          })}
          className={styles.inputField}
          type="text"
          inputMode="none"
          value={watchedValue}
          onClick={handleDateInputClick}
          onChange={handleInputChange}
          onBlur={handleBlur}
          isInvalid={isSubmitted && !!errors[question?.fieldName]}
          readOnly={readOnly}
        />
        {!isSubmitted && !errors[question?.fieldName] && (
          <LiaCalendarAltSolid className={styles.calendarIcon} />
        )}
      </div>
      {(nextPageClicked || isSubmitted) && errors[question?.fieldName] && (
        <ErrorMessage
          errors={errors}
          name={question?.fieldName}
          render={({ message }) => (
            <div className="text-validation">{message}</div>
          )}
        />
      )}
      {showCalendar && (
        <div ref={calendarRef} className="w-100">
          <CustomCalendar
            onChange={handleDateChange}
            value={watchedValue && !isNaN(Date.parse(watchedValue)) ? new Date(watchedValue) : undefined}
            maxDate={new Date(maxDate)}
          />
        </div>
      )}
    </div>
  );
}
