export function normalizeString(str: string | null | undefined): string {
    if (!str) {
        return "";
    }

    const map: { [key: string]: string } = {
        'ł': 'l',
        'Ł': 'L',
    };

    return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .split('')
        .map(char => map[char] || char)
        .join('')
        .toLowerCase()
        .trim();
}

export function formatNameForUrl(name: string): string {
    return normalizeString(name)
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9\-]/g, '');
  }