import ComponentWithDescription from "../ComponentWithDescription/ComponentWithDescription";
import { Button, ButtonGroup, Form, Row } from "react-bootstrap";
import styles from "./CompanyServiceForm.module.scss";
import Select, { MultiValue } from "react-select";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useGetCompanyDepartmentsByCompanyQuery } from "../../../redux/services/companyDepartmentApi";
import { toast } from "react-toastify";
import {
  useGetLoyaltyProgramsServiceCategoryQuery,
  usePatchLoyaltyProgramServiceMutation,
  usePostLoyaltyProgramServiceMutation,
  usePostLoyaltyProgramServiceUploadBatchMutation,
} from "../../../redux/services/loyaltyProgramApi";
import { useParams } from "react-router-dom";
import { ReactComponent as MinusIcon } from "../../../assets/Icons/SmallMinus.svg";
import { ReactComponent as PlusIcon } from "../../../assets/Icons/SmallPlus.svg";
import { ReactComponent as Info } from "../../../assets/Icons/Info.svg";
import PictureDropzone from "../../atoms/PictureDropzone/PictureDropzone";
import { usePostAttachmentsMutation } from "../../../redux/services/attachmentsApi";
import { ImageIds } from "../../../Helpers/convertImageIdToUrl";
import {
  customStyles,
  DropdownIndicator,
} from "../../../styles/reactSelectCustomStyles";
import TooltipIcon from "../../atoms/TooltipIcon";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/index";
import { UserRole } from "../../../redux/Slice/user-slice";
import { AUTH_DATA } from "../../../Helpers/Roles/authData";
import { LoyaltyProgramServiceCategory } from "../LoyaltyProgramServiceArticle/types";
import { addObjectsBetweenKey } from "../../../Helpers/addObjectsBetweenKey";
import { OptionSeparator } from "../../atoms/OptionSeparator/OptionSeparator";
import { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from "@reduxjs/toolkit/query";
import { SystemSetting } from "../../../redux/Slice/system-settings-slice";
import { useTranslation } from "react-i18next";
import TinyEditor from "components/organisms/TinyEditor/TinyEditor";
import DeleteServiceButton from "components/atoms/DeleteServiceButton/DeleteServiceButton";

interface CompanyServiceFormProps {
  reFetchCompany?: () => QueryActionCreatorResult<
    QueryDefinition<
      string | undefined,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      "Companies",
      any,
      "api"
    >
  >;
  data: any | null;
  isFirst?: boolean;
  handleEdit?: () => void;
  handleClose: () => void;
  isCopy?: boolean;
  isNewBenefit?: boolean;
  isUpdate?: boolean;
}

export const CompanyServiceForm = ({
  reFetchCompany,
  data,
  isFirst,
  isCopy,
  handleEdit,
  handleClose,
  isNewBenefit,
  isUpdate,
}: CompanyServiceFormProps) => {
  const { id: companyId } = useParams();
  const { authUser } = useSelector((state: RootState) => state.user);
  const { systemSettings } = useSelector(
    (state: RootState) => state.systemSettings
  );
  const userRolesArray = authUser.roles.map(
    (userRole: UserRole) => userRole.role.role
  );
  const [promoCodesAmountIsNotNull, setPromoCodesAmountIsNotNull] =
    useState(false);
  const [promoCodesEndAtIsNotNull, setPromoCodesEndAtIsNotNull] =
    useState(false);
  const [
    promoCodesReuseIntervalIsNotNull,
    setPromoCodesReuseIntervalIsNotNull,
  ] = useState(false);
  const [isPartnerCodesAllowed, setIsPartnerCodesAllowed] = useState(false);
  const [endAtIsNotNull, setEndAtIsNotNull] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [mainImage, setMainImage] = useState<ImageIds | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("uploadCsv");

  const { t } = useTranslation();

  const isAdmin: boolean = AUTH_DATA?.ADMIN_ROLES?.some((adminRole: string) =>
    userRolesArray.includes(adminRole)
  );

  const [
    patchLoyaltyProgramServiceMutation,
    { isSuccess: patchIsSuccess, error: patchError },
  ] = usePatchLoyaltyProgramServiceMutation();
  const [
    postLoyaltyProgramServiceMutation,
    { isSuccess: postIsSuccess, error: postError },
  ] = usePostLoyaltyProgramServiceMutation();
  const [postAttachments] = usePostAttachmentsMutation();
  const [postSponsorCodes] = usePostLoyaltyProgramServiceUploadBatchMutation();

  const { data: categoryData } = useGetLoyaltyProgramsServiceCategoryQuery({
    allService: isAdmin ? 1 : 0,
    hidden: isAdmin ? '1' : '0',
  });
  const { data: companyDepartmentData } =
    useGetCompanyDepartmentsByCompanyQuery(
      !isUpdate ? companyId : data?.company?.id
    );

  const categoryOptionsTest = categoryData?.data?.map(
    (loyaltyProgramServiceCategory: LoyaltyProgramServiceCategory) => ({
      value: loyaltyProgramServiceCategory?.id,
      label: loyaltyProgramServiceCategory?.name,
      priority: loyaltyProgramServiceCategory?.priority,
    })
  );

  const categoryOptions = addObjectsBetweenKey(
    categoryOptionsTest?.sort((a: CategoryOption, b: CategoryOption) => {
      if (a.priority === b.priority) {
        return a.label.localeCompare(b.label);
      }
      return a.priority - b.priority;
    }),
    "priority",
    <OptionSeparator />
  );

  const departmentOptions = companyDepartmentData?.data
    ?.filter(
      (departmentData: CompanyDepartmentData) => departmentData?.deleted === 0
    )
    ?.sort(
      (itemA: CompanyDepartmentData, itemB: CompanyDepartmentData) =>
        itemB.isMain - itemA.isMain
    )
    ?.map((departmentData: CompanyDepartmentData) => ({
      value: departmentData?.id,
      label: departmentData?.name,
    }));

  const localizationByCategory: string[] = systemSettings
    ?.filter(
      (systemSetting: SystemSetting) =>
        systemSetting.type === "online_category_all_province"
    )
    ?.map((v: SystemSetting) => v?.value);

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    control,
    clearErrors,
    formState: { errors },
  } = useForm<CompanyServiceFormData>({
    defaultValues: {
      name: data?.name || "",
      category:
        data?.loyaltyProgramServiceCategory
          ?.map((obj: { id: number }) => obj?.id)
          ?.join(",") || "",
      department:
        data?.loyaltyProgramServiceDepartment
          ?.map((obj: { id: number }) => obj?.id)
          ?.join(",") || "",
      promoCodesAmount: data?.promoCodesAmount || 0,
      promoCodesEndAt: data?.promoCodesEndAt || null,
      promoCodesReuseInterval: data?.promoCodesReuseInterval || 0,
      startAt: data?.startAt || new Date().toISOString()?.split("T")[0],
      endAt: data?.endAt || null,
      description: data?.description || "",
      conditions: data?.conditions || "",
      imageId: null,
      loyaltyProgramId: data?.loyaltyProgram?.id || 1,
      multipleUseCodesAllowed: data?.reusableCode || false,
    },
  });

  useEffect(() => {
    if (data) {
      let image: ImageIds | null = null;
      if (data?.imageIds?.length > 0) {
        image = data?.imageIds?.find((value: ImageIds) => value?.meta === null);
        if (image) {
          setMainImage(image);
        } else {
          image = null;
          setMainImage(null);
        }
      }

      setPromoCodesAmountIsNotNull(!!data?.promoCodesAmount);
      setPromoCodesEndAtIsNotNull(!!data?.promoCodesEndAt);
      setPromoCodesReuseIntervalIsNotNull(!!data?.promoCodesReuseInterval);
      setEndAtIsNotNull(!!data?.endAt);

      setValue("name", data?.name);
      setValue(
        "category",
        data?.loyaltyProgramServiceCategory
          ?.map((obj: { id: number }) => obj?.id)
          ?.join(",")
      );
      setValue(
        "department",
        data?.loyaltyProgramServiceDepartment
          ?.map((obj: { id: number }) => obj?.id)
          ?.join(",")
      );
      setValue("promoCodesAmount", data?.promoCodesAmount || 0);
      setValue(
        "promoCodesEndAt",
        data?.promoCodesEndAt ? data?.promoCodesEndAt?.split("T")?.[0] : null
      );
      setValue("promoCodesReuseInterval", data?.promoCodesReuseInterval || 0);
      setValue("startAt", data?.startAt?.split("T")?.[0]);
      setValue("endAt", data?.endAt ? data?.endAt?.split("T")?.[0] : null);
      setValue("description", data?.description);
      setValue("conditions", data?.conditions);
      setValue("imageId", image ? image?.id : null);
      setValue("loyaltyProgramId", data?.loyaltyProgram?.id);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (postIsSuccess || patchIsSuccess) {
      if (reFetchCompany) {
        reFetchCompany();
      }
      if (handleEdit) {
        handleEdit();
      }
    }

    if (patchIsSuccess) {
      toast.success(t("toast_companyServiceForm_benefit_update_success"));
    } else if (postIsSuccess) {
      toast.success(t("toast_companyServiceForm_benefit_add_success"));
    } else if (patchError || postError) {
      toast.error(t("toast_companyServiceForm_benefit_error"));
    }
    // eslint-disable-next-line
  }, [postIsSuccess, postError, patchIsSuccess, patchError]);

  useEffect(() => {
    if (errors.conditions || errors.description) {
      if (watch("description").length === 0) {
        setError("description", {
          message: 'Pole "Informacje o rabacie" nie może być puste',
        });
      } else {
        clearErrors("description");
      }
      if (watch("conditions").length === 0) {
        setError("conditions", {
          message: 'Pole "Warunki promocji" nie może być puste',
        });
      } else {
        clearErrors("conditions");
      }
    }
    // eslint-disable-next-line
  }, [watch("description"), watch("conditions")]);

  useEffect(() => {
    if (promoCodesEndAtIsNotNull && endAtIsNotNull) {
      const end = new Date(String(watch("endAt")));
      const promoCodesEnd = new Date(String(watch("promoCodesEndAt")));
      if (promoCodesEnd < end) {
        setValue("promoCodesEndAt", watch("endAt"));
      }
    }
    // eslint-disable-next-line
  }, [watch("endAt")]);

  useEffect(() => {
    if (promoCodesEndAtIsNotNull && endAtIsNotNull) {
      const start = new Date(String(watch("startAt")));
      const end = new Date(String(watch("endAt")));
      if (end < start) {
        setValue("endAt", watch("startAt"));
      }
    }
    // eslint-disable-next-line
  }, [watch("startAt")]);

  useEffect(() => {
    if (
      localizationByCategory.some((element: string) =>
        watch("category")?.split(",").includes(element)
      )
    ) {
      const main = companyDepartmentData?.data;
      if (companyDepartmentData?.data && main.length) {
        setValue("department", String(main?.[0]?.id || ""));
      }
    }
    // eslint-disable-next-line
  }, [watch("category")]);

  const decreaseValue = (
    name: "promoCodesAmount" | "promoCodesReuseInterval"
  ) => {
    const prev = getValues(name);
    if (prev) {
      setValue(name, prev < 1 ? 0 : Number(prev) - 1);
    }
  };

  const increaseValue = (
    name: "promoCodesAmount" | "promoCodesReuseInterval"
  ) => {
    const prev = getValues(name);
    if (prev || prev === 0) {
      setValue(name, Number(prev) + 1);
    }
  };

  const handlePicture = async (value: File) => {
    setFile(value);
  };

  const benefitId = data?.id;

  const submitHandler: SubmitHandler<CompanyServiceFormData | any> = async (
    data
  ) => {

    if (data?.conditions.length > 0 && data?.description.length > 0) {
      const newData: CompanyDepartmentFormData | any = {
        ...data,
        promoCodesAmount: promoCodesAmountIsNotNull ? data?.promoCodesAmount : null,
        promoCodesReuseInterval: promoCodesReuseIntervalIsNotNull
          ? data?.promoCodesReuseInterval
          : null,
        promoCodesEndAt: promoCodesEndAtIsNotNull ? data?.promoCodesEndAt : null,
        endAt: endAtIsNotNull ? data?.endAt : null,
        companyId: companyId ? +companyId : data?.company?.id,
        partnerCodesAllowed: isPartnerCodesAllowed,
        multipleUseCodesAllowed: selectedOption === "reusableCode",
        category: data?.category?.split(",") || [],
      };
  
      let imageId;
      let serviceResponse;
  
      if (file) {
        const attachmentsData = await postAttachments({
          data: file,
          attachmentType: "loyalty-program-services",
        });
  
        if ("data" in attachmentsData) {
          const { data: attachmentData } = attachmentsData;
          imageId = attachmentData?.data?.id ?? null;
        }
      }
  
      if (data && !isCopy && !isNewBenefit) {
        const newDataSend = {
          ...newData,
          imageId: imageId,
        };
        serviceResponse = await patchLoyaltyProgramServiceMutation({
          id: benefitId,
          data: newDataSend,
        });
      } else {
        if (newData?.id) {
          delete newData?.id;
        }
        newData.imageId = imageId;
  
        serviceResponse = await postLoyaltyProgramServiceMutation({
          data: newData,
        });
      }
  
      if (
        serviceResponse &&
        "data" in serviceResponse &&
        serviceResponse.data !== undefined
      ) {
        let dataForUpload: { file?: File; codes?: string } = {};
  
        if (selectedOption === "manualEntry") {
          dataForUpload = { codes: data?.manualCodes };
        } else if (selectedOption === "uploadCsv") {
          const file = data?.partnerCodesFile;
          if (file instanceof File) {
            dataForUpload = { file };
          }
        } else if (selectedOption === "reusableCode") {
          dataForUpload = { codes: data?.multiUseCode };
        }
  
        const dataToSend = {
          serviceId: serviceResponse?.data?.data?.id,
          data: dataForUpload,
        };
  
        if (dataToSend?.data?.codes || dataToSend?.data?.file) {
          try {
            const uploadResponse = await postSponsorCodes(dataToSend).unwrap();
  
            const skipped = uploadResponse?.skipped;
            if (skipped?.length > 0) {
              toast.error(
                `${skipped?.length} kodów zostało pominiętych, ponieważ już istnieją w systemie jako duplikaty.`
              );
            } else if (uploadResponse?.statusCode === 400) {
              toast.error("Wystąpił błąd podczas wgrywania kodów.");
            } else {
              toast.success("Kody zostały pomyślnie wgrane.");
            }
          } catch (error) {
            toast.error("Wystąpił błąd podczas wgrywania kodów.");
          }
        }
      }
    } else {
      if (watch("description").length === 0) {
        setError("description", {
          message: 'Pole "Informacje o rabacie" nie może być puste',
        });
      } else {
        clearErrors("description");
      }
      if (watch("conditions").length === 0) {
        setError("conditions", {
          message: 'Pole "Warunki promocji" nie może być puste',
        });
      } else {
        clearErrors("conditions");
      }
    }
  
    handleClose();
  };
  

  const handleChangeUploadOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value);
  };

  return (
    <Form
      className={"d-flex flex-column w-100 "}
      onSubmit={handleSubmit(submitHandler)}
    >
      <Row className={""}>
        {!isUpdate && (
          <span className={"pb-3 text-grey-2 fs-12 fw-semibold ls-6"}>
            Dodawanie benefitu
          </span>
        )}
        <ComponentWithDescription
          label={"Nazwa benefitu*"}
          labelFs={`fs-16 ${errors.name ? "text-danger" : "text-dark-blue"}`}
          desc={"Max. 75 znaków"}
          descFs={"fs-12 text-grey"}
          className="my-2 pb-1"
        >
          <Form.Control
            type="text"
            id="name"
            aria-describedby="name"
            className={`w-100 text-dark-blue ${styles.input} `}
            {...register("name", {
              required: { value: true, message: "Pole wymagane" },
              maxLength: { value: 75, message: "Max. 75 znaków" },
            })}
            isInvalid={!!errors?.name}
          />
          {errors?.name && (
            <Form.Control.Feedback type={"invalid"}>
              {errors?.name?.message}
            </Form.Control.Feedback>
          )}
        </ComponentWithDescription>
        <ComponentWithDescription className="my-2">
          <Row className={""}>
            {categoryOptions && (
              <div className={""}>
                <label className={"fs-16 pb-1 text-dark-blue d-flex"}>
                  <span className={`${errors?.category ? "text-danger" : ""}`}>
                    Kategoria benefitu*
                  </span>
                  <div
                    className={"d-flex align-items-center text-primary ps-2"}
                  >
                    <TooltipIcon desc="Kategoria pozwoli odnaleźć benefit zainteresowanym użytkownikom. Możesz wybrać więcej niż jedną kategorię benefitu.">
                      <div
                        className={"cursor-pointer d-flex align-items-center"}
                      >
                        <Info />
                      </div>
                    </TooltipIcon>
                  </div>
                </label>
                <Controller
                  name={"category"}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Pole wymagane",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={categoryOptions?.filter((obj: ReactSelectOption) =>
                        value?.split(",")?.includes(obj?.value?.toString())
                      )}
                      isMulti={true}
                      classNamePrefix="select"
                      placeholder="Wybierz z listy"
                      isClearable={false}
                      isSearchable={true}
                      id="categoryService"
                      name="categoryService"
                      options={categoryOptions}
                      onChange={(options: MultiValue<ReactSelectOption[]>) => {
                        if (options?.length) {
                          const category = options
                            ?.map((v: any) => (v.value !== 0 ? v.value : null))
                            ?.filter((n: any) => n)
                            ?.join(",");
                          return onChange(category);
                        }
                        return onChange("");
                      }}
                      styles={customStyles(!!errors?.category)}
                      components={{ DropdownIndicator }}
                      formatOptionLabel={(option, { context }) => {
                        if (context === "menu") {
                          if (option.type === OptionSeparator) {
                            return <OptionSeparator />;
                          }
                          return option.label;
                        }
                        return option.label;
                      }}
                    />
                  )}
                />
                {errors.category && (
                  <div className={"text-danger"}>
                    {errors?.category?.message}
                  </div>
                )}
              </div>
            )}
          </Row>
        </ComponentWithDescription>
        <ComponentWithDescription className="my-2">
          <Row>
            {departmentOptions && (
              <div>
                <label
                  className={`fs-16 pb-1 ${
                    errors.department ? "text-danger" : "text-dark-blue"
                  }`}
                >
                  Twoje lokalizacje realizujące rabat*
                </label>
                <Controller
                  name={"department"}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Pole wymagane",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={departmentOptions.filter(
                        (obj: ReactSelectOption) =>
                          value?.split(",")?.includes(obj.value.toString())
                      )}
                      isMulti={true}
                      isDisabled={localizationByCategory.some(
                        (element: string) =>
                          watch("category")?.split(",").includes(element)
                      )}
                      classNamePrefix="select"
                      placeholder="Wybierz z listy"
                      isClearable={false}
                      isSearchable={true}
                      id="localizationsService"
                      name="localizationsService"
                      options={departmentOptions}
                      onChange={(options: MultiValue<ReactSelectOption[]>) => {
                        if (options?.length) {
                          const department = options
                            ?.map((v: any) => (v.value !== 0 ? v.value : null))
                            ?.filter((n: any) => n)
                            ?.join(",");
                          return onChange(department);
                        }
                        return onChange("");
                      }}
                      styles={customStyles(!!errors?.department)}
                      components={{ DropdownIndicator }}
                    />
                  )}
                />
                {errors?.department && (
                  <div className={"text-danger"}>
                    {errors?.department?.message}
                  </div>
                )}
              </div>
            )}
          </Row>
        </ComponentWithDescription>
        <ComponentWithDescription
          label={"Grafika rabatu"}
          labelFs={"fs-16"}
          className="my-2 w-100"
          desc="Preferowane grafiki to 1500x850. Akceptowane formaty plików: jpg, png, tiff."
        >
          <PictureDropzone
            handlePicture={handlePicture}
            imageUrl={mainImage?.accessUrl || null}
          />
        </ComponentWithDescription>
        <span className={"py-3 text-grey-2 fs-12 fw-semibold ls-6"}>
          Ustawienia kodów i ich dostępność
        </span>
        {!data?.multipleUseCodesAllowed ? (
          <div>
            <Form.Check
              type="switch"
              checked={isPartnerCodesAllowed}
              id="isPartnerCodesAllowed"
              label="Dodawanie kodów własnych przez partnera"
              onChange={() => setIsPartnerCodesAllowed((prev) => !prev)}
            />
            {isPartnerCodesAllowed && (
              <div className="mt-2">
                <div className="d-flex flex-column flex-md-row">
                  <div className="me-3 d-flex">
                    <Form.Check
                      type="radio"
                      label="Wgraj plik CSV z kodami"
                      name="inputMethod"
                      value="uploadCsv"
                      checked={selectedOption === "uploadCsv"}
                      onChange={handleChangeUploadOption}
                    />
                    <TooltipIcon desc="Plik musi być w formacie CSV. Każdy kod musi być umieszczony w osobnej linii i zakończony przecinkiem. Jest to wymagane dla poprawnego przetwarzania kodów.">
                      <div
                        className={
                          "cursor-pointer d-flex align-items-center text-primary ms-1"
                        }
                      >
                        <Info />
                      </div>
                    </TooltipIcon>
                  </div>
                  <div className="me-3 d-flex">
                    <Form.Check
                      type="radio"
                      label="Wpisz kody jednorazowego użycia"
                      name="inputMethod"
                      value="manualEntry"
                      checked={selectedOption === "manualEntry"}
                      onChange={handleChangeUploadOption}
                    />
                    <TooltipIcon desc="Każdy kod wpisywany ręcznie musi być umieszczony w osobnej linii i zakończony przecinkiem. Jest to wymagane dla poprawnego przetwarzania kodów.">
                      <div
                        className={
                          "cursor-pointer d-flex align-items-center text-primary ms-1"
                        }
                      >
                        <Info />
                      </div>
                    </TooltipIcon>
                  </div>
                  <div className="d-flex">
                    <Form.Check
                      type="radio"
                      label="Wpisz kod wielorazowego użycia"
                      name="inputMethod"
                      value="reusableCode"
                      checked={selectedOption === "reusableCode"}
                      onChange={handleChangeUploadOption}
                    />
                    <TooltipIcon desc="Pojedynczy kod bez limitu użyć. Nie powinien zawierać znaków białych (takich jak spacje), kropek ani przecinków.">
                      <div
                        className={
                          "cursor-pointer d-flex align-items-center text-primary ms-1"
                        }
                      >
                        <Info />
                      </div>
                    </TooltipIcon>
                  </div>
                </div>

                {selectedOption === "uploadCsv" && (
                  <div className="mt-2">
                    <Controller
                      name="partnerCodesFile"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <Form.Control
                          type="file"
                          onChange={(e) => {
                            const file = (e.target as HTMLInputElement)
                              ?.files?.[0];
                            if (file) {
                              onChange(file);
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                )}
                {selectedOption === "manualEntry" && (
                  <div className="mt-2">
                    <Controller
                      name="manualCodes"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Form.Control
                          as="textarea"
                          onChange={(e) => onChange(e.target.value)}
                          value={value}
                        />
                      )}
                    />
                  </div>
                )}
                {selectedOption === "reusableCode" && (
                  <div className="mt-2">
                    <Controller
                      name="multiUseCode"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Form.Control
                          type="text"
                          onChange={(e) => onChange(e.target.value)}
                          value={value}
                        />
                      )}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <p>
            W trybie edycji nie ma możliwości zmiany kodu wielokrotnego użytku.
          </p>
        )}

        <div
          className={
            "mx-0 d-flex flex-lg-row flex-column justify-content-lg-between align-items-lg-center fs-16 py-2"
          }
        >
          {!isPartnerCodesAllowed && (
            <>
              <Form.Check
                type="switch"
                checked={promoCodesAmountIsNotNull}
                id="promoCodesAmountIsNotNull-switch"
                label="Ograniczona pula wszystkich dostepnych kodów"
                onChange={() => setPromoCodesAmountIsNotNull((prev) => !prev)}
              />
              <ButtonGroup className={styles.inputGroup}>
                <Button
                  variant="outline-primary"
                  onClick={() => decreaseValue("promoCodesAmount")}
                  disabled={!promoCodesAmountIsNotNull}
                >
                  <MinusIcon />
                </Button>
                <Form.Control
                  type="number"
                  disabled={!promoCodesAmountIsNotNull}
                  {...register("promoCodesAmount", {
                    min: {
                      value: 0,
                      message: "Wartość nie może być mniejsza od 0",
                    },
                  })}
                  isInvalid={!!errors?.promoCodesAmount}
                />
                <Button
                  variant="outline-primary"
                  onClick={() => increaseValue("promoCodesAmount")}
                  disabled={!promoCodesAmountIsNotNull}
                >
                  <PlusIcon />
                </Button>
              </ButtonGroup>
            </>
          )}
        </div>
        <div
          className={
            "mx-0 d-flex flex-lg-row flex-column justify-content-lg-between align-items-lg-center fs-16 py-2"
          }
        >
          <Form.Check
            type="switch"
            checked={promoCodesEndAtIsNotNull}
            id="promoCodesEndAtIsNotNull-switch"
            label="Ograniczona data ważności kodu"
            onChange={() => {
              setPromoCodesEndAtIsNotNull((prev) => !prev);
              setEndAtIsNotNull(true);
            }}
          />
          <Form.Control
            className={styles.inputDate}
            disabled={!promoCodesEndAtIsNotNull}
            type="date"
            {...(watch("endAt") && { min: String(watch("endAt")) })}
            {...register("promoCodesEndAt")}
          />
        </div>
        <div
          className={
            "mx-0 d-flex flex-lg-row flex-column justify-content-lg-between align-items-lg-center fs-16 py-2"
          }
        >
          <Form.Check
            type="switch"
            checked={promoCodesReuseIntervalIsNotNull}
            id="promoCodesReuseIntervalIsNotNull-switch"
            label="Liczba dni potrzebnych do wygenerowania nowego kodu dla jednego użytkownika"
            onChange={() =>
              setPromoCodesReuseIntervalIsNotNull((prev) => !prev)
            }
          />

          {promoCodesReuseIntervalIsNotNull ? (
            <ButtonGroup className={styles.inputGroup}>
              <Button
                variant="outline-primary"
                name={"promoCodesReuseInterval"}
                onClick={() => decreaseValue("promoCodesReuseInterval")}
                disabled={!promoCodesReuseIntervalIsNotNull}
              >
                <MinusIcon />
              </Button>
              <Form.Control
                type="number"
                min={0}
                disabled={!promoCodesReuseIntervalIsNotNull}
                {...register("promoCodesReuseInterval", {
                  min: { value: 0, message: "Można tylko dodatnie liczby" },
                })}
                isInvalid={!!errors?.promoCodesReuseInterval}
              />
              <Button
                variant="outline-primary"
                onClick={() => increaseValue("promoCodesReuseInterval")}
                disabled={!promoCodesReuseIntervalIsNotNull}
              >
                <PlusIcon />
              </Button>
            </ButtonGroup>
          ) : (
            <div className={"d-flex align-items-center"} style={{ height: 38 }}>
              <span className={"text-end"}>kod jednorazowego użycia</span>
            </div>
          )}
        </div>

        <span className={"py-3 text-grey-2 fs-12 fw-semibold ls-6"}>
          Okres trwania rabatu
        </span>
        <div
          className={
            "mx-0 d-flex flex-lg-row flex-column justify-content-lg-between align-items-lg-center fs-16 py-2"
          }
        >
          <div>Data startu rabatu</div>
          <Form.Control
            className={styles.inputDate}
            type="date"
            {...(watch("endAt") &&
              endAtIsNotNull && { max: String(watch("endAt")) })}
            {...register("startAt")}
          />
        </div>
        <div
          className={
            "mx-0 d-flex flex-lg-row flex-column justify-content-lg-between align-items-lg-center fs-16 py-2 gap-2"
          }
        >
          <Form.Check
            type="switch"
            checked={endAtIsNotNull || promoCodesEndAtIsNotNull}
            id="endAtIsNotNull-switch"
            label="Data zakończenia rabatu"
            onChange={() => {
              if (!promoCodesEndAtIsNotNull) {
                setEndAtIsNotNull((prev) => !prev);
              }
            }}
          />
          <Form.Control
            className={styles.inputDate}
            disabled={!endAtIsNotNull}
            type="date"
            {...(watch("startAt") && { min: String(watch("startAt")) })}
            {...register("endAt")}
          />
        </div>
        <span className={"py-3 text-grey-2 fs-12 fw-semibold ls-6"}>
          Szczegóły rabatu
        </span>
        <ComponentWithDescription
          label={"Informacje o rabacie*"}
          labelFs={`fs-16 ${
            errors?.description ? "text-danger" : "text-dark-blue"
          }`}
          className="my-2"
          desc="Max. 5000 znaków"
          descFs={"fs-12 text-grey d-flex"}
        >
          <TinyEditor
            setValue={setValue}
            data={watch("description")}
            {...register("description", {
              required: { value: true, message: "Pole wymagane" },
              maxLength: { value: 5000, message: "Max. 5000 znaków" },
            })}
          />
          {errors?.description && (
            <p className="fs-14 text-danger mb-0 mt-1">
              {errors?.description?.message}
            </p>
          )}
        </ComponentWithDescription>
        <ComponentWithDescription
          label={"Warunki promocji*"}
          labelFs={`fs-16 ${
            errors.conditions ? "text-danger" : "text-dark-blue"
          }`}
          className="my-2 border-danger border-2"
          desc="Max. 5000 znaków"
          descFs={"fs-12 text-grey d-flex"}
        >
          <TinyEditor
            setValue={setValue}
            data={watch("conditions")}
            {...register("conditions", {
              required: { value: true, message: "Pole wymagane" },
              maxLength: { value: 5000, message: "Max. 5000 znaków" },
            })}
          />
          {errors?.conditions && (
            <p className="fs-14 text-danger mb-0 mt-1">
              {errors?.conditions?.message}
            </p>
          )}
        </ComponentWithDescription>
      </Row>

      <div className="d-flex gap-2 mt-2 justify-content-end">
        {isUpdate && <DeleteServiceButton data={data} />}
        <Button
          className="btn-outline-dark-blue"
          onClick={() => {
            handleClose();
          }}
        >
          ANULUJ
        </Button>

        <Button type="submit" value="Submit">
          {data !== null && !isCopy
            ? "AKTUALIZUJ"
            : isFirst
            ? "UTWÓRZ"
            : "DODAJ"}
        </Button>
      </div>
    </Form>
  );
};