import { provinces } from "endpoints/endpoints";
import { apiSlice } from "./apiSlice";

export interface ProvinceData {
  id: number;
  name: string;
  color: string;
}

interface MetaData {
  status: string;
  totalResults: number;
  currentLimit: number;
  currentOffset: number;
}

interface ProvinceResponse {
  data: ProvinceData[];
  meta: MetaData;
}

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Provinces"],
});

export const provincesApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getProvinces: builder.query<ProvinceResponse, any>({
      query: () => {
        return {
          url: `${provinces}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Provinces"],
    }),
  }),
});

export const { useGetProvincesQuery, useLazyGetProvincesQuery } = provincesApi;
